.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1250;
  background-color: rgba(0, 0, 0, 0.596);
  width: 400vw;
  min-height: 400vh;
}

.noBackdrop{
  display: none;
}