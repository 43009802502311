.card {
  overflow-y: scroll;
  max-height: 90vh;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  background: #f5f5f5;

  /* Hide scrollbar in Firefox */
  scrollbar-width: none;
  /* Hide scrollbar in IE and Edge */
  -ms-overflow-style: none;
  /* Hide scrollbar in Webkit browsers */
  &::-webkit-scrollbar {
    display: none;
  }
}

.label-header {
  font-weight: bold;
  /* color: white; */
}

.segment-container {
  background-color: rgb(241, 241, 241);
  /* background-color: rgb(191, 219, 226); */
}

.subheading {
  display: flex;
  justify-content: start;
  font-size: 14px;
  font-weight: bold;
}
.colon {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}
.text {
  display: flex;
  justify-content: start;
  font-size: 14px;
}
