.th-custom-space  .ant-space-item:last-child{
    width: 100%;
}

.th-custom-space-1  .ant-space-item:first-child{
    width: 100%;
}

.th-other-sender{
    padding: 10px;
    background: #ececec;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
}