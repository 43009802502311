@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 450px) {
  .role {
    width: 230px !important;
  }
}

@media only screen and (max-width: 450px) {
  .writtenupdate {
    width: "80%";
    margin-left: 30;
  }
}

@media only screen and (max-width: 450px) {
  .textList {
    width: 230px !important;
  }
}

@media only screen and (max-width: 450px) {
  .textDisplay {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 450px) {
  .headings {
    font-size: 24px !important;
  }
}

.page_title {
  font-size: large;
  font-weight: bold;
  margin-bottom: 10px;
}

.page_body {
  padding: 5px;
  padding: 16px 32px 16px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  width: 100%;
}


.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: end;
}

.w-100 {
  width: 100%;
}
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  border-radius: 10px;
}

.th-custom-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
}
.th-custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #8c8c8c;
}

.details-modal-subheading {
  display: flex;
  justify-content: end;
  font-size: 16px;
  font-weight: bold;
}
.details-modal-colon {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}
.details-modal-text {
  display: flex;
  justify-content: start;
  font-size: 16px;
}

.th-hidden-scroll::-webkit-scrollbar {
  display: none;
}

.th-hidden-scroll:hover:-webkit-scrollbar {
  /* display: none; */
}

p{
  margin-top: 0;
    margin-bottom: 1rem;
}

.th-12{
  font-size: 12px;
}

.th-pointer{
  cursor: pointer;
}

.m-0{
  margin: 0;
}
.backdrop_backdrop__25dJ0{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1250;
  background-color: rgba(0, 0, 0, 0.596);
  width: 400vw;
  min-height: 400vh;
}

.backdrop_noBackdrop__1Ff4T{
  display: none;
}
@media only screen and (max-width: 800px) {
.gridee {
      margin-left: 25%;
      margin-right: 25%;
    }
  }
.modal_modal__2tJm3{
  position: fixed;
  width: 50%;
  min-width: 40%;
  margin: auto;
  /* height: 1vh; */
  max-height : 95vh !important;
  min-height: 430px !important;
  z-index: 1300;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: auto;
}
.modal_modal__small__330y5{
  position: fixed;
  width: 30%;
  min-width: 30%;
  margin: auto;
  max-height: 40vh !important;
  min-height: 150px !important;
  z-index: 1300;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #ffffff;
  overflow: auto;
}

.modal_modal__medium__IM3W3{
  position: fixed;
  width: 40%;
  min-width: 40%;
  margin: auto;
  max-height: 70vh !important;
  min-height: 300px !important;
  z-index: 1300;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #ffffff;
  overflow: auto;
}

.modal_modal__large__WHzON{
  position: fixed;
  width: 80%;
  min-width: 80%;
  margin: auto;
  max-height : 95vh !important;
  min-height: 430px !important;
  z-index: 1300;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #ffffff;
  overflow: auto;
}

.modal_noModal__2cz29{
  display: none;
}
.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}


/* VARIABLES */

/* :root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
} */


/* GENERAL */

* {
  box-sizing: border-box;
}

/* body {
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color);
    background: var(--bg-color);
    position: relative;
} */

/* header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
} */

/* header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: .25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
} */


/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  /* display: block; */
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
}

.calendar .header .icon:hover {
  -webkit-transform: scale(1.75);
          transform: scale(1.75);
  transition: .25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  margin-right: 10em;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  color: var(--text-color);
  /* background: #d4cccb; */
  box-sizing: border-box;
  /* margin: 0.1px; */
  position: relative;
  height: 5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  /* background: var(--neutral-color); */
  transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  margin-right: 1em;
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: .75em;
  right: .75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg  {
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}
p.Mui-required.MuiFormHelperText-contained.MuiFormHelperText-filled.MuiFormHelperText-marginDense.MuiFormHelperText-root {
  font-size: 14px;
  font-weight: bolder;
}
p.MuiFormHelperText-root.MuiFormHelperText-contained.Mui-required.MuiFormHelperText-marginDense {
  font-size: 14px;
  font-weight: bolder;
}
p.MuiFormHelperText-root.MuiFormHelperText-contained.MuiFormHelperText-marginDense {
  font-size: 14px;
  font-weight: bolder;
}
p.MuiFormHelperText-root.MuiFormHelperText-filled {
  font-size: 14px;
  font-weight: bolder;
}
h2#responsive-dialog-title {
  font-size: 30px;
  font-weight: bolder;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 1000px !important;
}
p.MuiFormHelperText-root {
  font-size: 14px;
  font-weight: bolder;
}
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: gray !important;
}
.MuiFormHelperText-root.Mui-error{
  color: gray !important;
}

.farwordOfferLetter{
  justify-content: center !important;
  height: 250px !important;
  overflow-y: scroll !important;
}

.myDisplay{
  display: none;
}


.offer-preview{
  height: calc(100vh - 200px);
  overflow-y: scroll;
}

.big-tag{
  font-size: 14px;
  padding: 2px 14px;
  font-weight: 400;
  max-width: 100%;
}
     

@media only screen and (max-width: 600px) {
    .makeStyles-content-11 {
     padding: 0px !important;
    }
    .Process-processHead-44{
        margin: 0px !important;
    }
    .Process-leftBox-41 {
        padding: 0px !important;
    }
    .MuiStepper-root{
        padding: 0px !important;
    }
}

.applicantOfferLetter{
    margin: "0px 0px 5px 0px";

}
.offerLetterMainBox{
    width: "80%";
    height: "auto";
    margin: "0 auto";
    box-shadow: " -3px -3px 7px #b4c4d070 , 3px 3px 7px #52628870 ";
    display: "grid";
    grid-template-columns: "repeat(2 , 1fr)";
    padding: "15px 75px 15px 15px";
    
}
@media only screen and (max-width: 600px) {
    .offerLetterMainBox{
    width: '70%';
    height: 'auto';
    margin: '0 auto';
    display: 'grid';
    padding:'0px !important';
    box-shadow: '-3px -3px 7px #b4c4d070, 3px 3px 7px #52628870';
    grid-template-columns: 'repeat(2 , 1fr)';
    }
}
.offerLetterLeftBox{
    padding: "5px"
}
.css-uhb5lp{
    max-width: 1000px !important;
}
.css-uhb5lp{
    max-width: 1000px !important;
}
.th-custom-space  .ant-space-item:last-child{
    width: 100%;
}

.th-custom-space-1  .ant-space-item:first-child{
    width: 100%;
}

.th-other-sender{
    padding: 10px;
    background: #ececec;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
}
.th-drawer{
    width: 70px;
    height: 100vh;
    background-color: white;
    transition: width 0.1s ease-in-out;
    overflow-y: scroll;
    box-shadow: 12px 0px 15px -8px rgba(0,0,0,0.1);
}
.drawer-open{
    width: 300px;
}

.th-drawer::-webkit-scrollbar {
    width: 0.5em;
  }
  
  .th-drawer::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .th-mobile-sidebar{
    background: #2a3649;
}

/* .th-mobile-sidebar .ant-drawer .ant-drawer-body{
    padding: 0 !important;
} */

.th-mobile-sidebar .ant-drawer-wrapper-body .ant-drawer-body{
    padding: 0 !important;
}

.ant-layout .ant-layout-sider-trigger{
    background:#2a3649 !important ;
}


.ant-layout-sider::-webkit-scrollbar {
    display: none;
  }
.card {
  overflow-y: scroll;
  max-height: 90vh;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  background: #f5f5f5;

  /* Hide scrollbar in Firefox */
  scrollbar-width: none;
  /* Hide scrollbar in IE and Edge */
  -ms-overflow-style: none;
  /* Hide scrollbar in Webkit browsers */
  &::-webkit-scrollbar {
    display: none;
  }
}

.label-header {
  font-weight: bold;
  /* color: white; */
}

.segment-container {
  background-color: rgb(241, 241, 241);
  /* background-color: rgb(191, 219, 226); */
}

.subheading {
  display: flex;
  justify-content: start;
  font-size: 14px;
  font-weight: bold;
}
.colon {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}
.text {
  display: flex;
  justify-content: start;
  font-size: 14px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



.th-hidden-scrollbar::-webkit-scrollbar{
  display: none;
}

.text-capitalize{
  text-transform: capitalize;
}

