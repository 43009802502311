.th-drawer{
    width: 70px;
    height: 100vh;
    background-color: white;
    transition: width 0.1s ease-in-out;
    overflow-y: scroll;
    box-shadow: 12px 0px 15px -8px rgba(0,0,0,0.1);
}
.drawer-open{
    width: 300px;
}

.th-drawer::-webkit-scrollbar {
    width: 0.5em;
  }
  
  .th-drawer::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .th-mobile-sidebar{
    background: #2a3649;
}

/* .th-mobile-sidebar .ant-drawer .ant-drawer-body{
    padding: 0 !important;
} */

.th-mobile-sidebar .ant-drawer-wrapper-body .ant-drawer-body{
    padding: 0 !important;
}

.ant-layout .ant-layout-sider-trigger{
    background:#2a3649 !important ;
}


.ant-layout-sider::-webkit-scrollbar {
    display: none;
  }