body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 450px) {
  .role {
    width: 230px !important;
  }
}

@media only screen and (max-width: 450px) {
  .writtenupdate {
    width: "80%";
    margin-left: 30;
  }
}

@media only screen and (max-width: 450px) {
  .textList {
    width: 230px !important;
  }
}

@media only screen and (max-width: 450px) {
  .textDisplay {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 450px) {
  .headings {
    font-size: 24px !important;
  }
}

.page_title {
  font-size: large;
  font-weight: bold;
  margin-bottom: 10px;
}

.page_body {
  padding: 5px;
  padding: 16px 32px 16px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  width: 100%;
}


.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: end;
}

.w-100 {
  width: 100%;
}
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  border-radius: 10px;
}

.th-custom-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
}
.th-custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #8c8c8c;
}

.details-modal-subheading {
  display: flex;
  justify-content: end;
  font-size: 16px;
  font-weight: bold;
}
.details-modal-colon {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}
.details-modal-text {
  display: flex;
  justify-content: start;
  font-size: 16px;
}

.th-hidden-scroll::-webkit-scrollbar {
  display: none;
}

.th-hidden-scroll:hover:-webkit-scrollbar {
  /* display: none; */
}

p{
  margin-top: 0;
    margin-bottom: 1rem;
}

.th-12{
  font-size: 12px;
}

.th-pointer{
  cursor: pointer;
}

.m-0{
  margin: 0;
}