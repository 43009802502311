     

@media only screen and (max-width: 600px) {
    .makeStyles-content-11 {
     padding: 0px !important;
    }
    .Process-processHead-44{
        margin: 0px !important;
    }
    .Process-leftBox-41 {
        padding: 0px !important;
    }
    .MuiStepper-root{
        padding: 0px !important;
    }
}

.applicantOfferLetter{
    margin: "0px 0px 5px 0px";

}
.offerLetterMainBox{
    width: "80%";
    height: "auto";
    margin: "0 auto";
    box-shadow: " -3px -3px 7px #b4c4d070 , 3px 3px 7px #52628870 ";
    display: "grid";
    grid-template-columns: "repeat(2 , 1fr)";
    padding: "15px 75px 15px 15px";
    
}
@media only screen and (max-width: 600px) {
    .offerLetterMainBox{
    width: '70%';
    height: 'auto';
    margin: '0 auto';
    display: 'grid';
    padding:'0px !important';
    box-shadow: '-3px -3px 7px #b4c4d070, 3px 3px 7px #52628870';
    grid-template-columns: 'repeat(2 , 1fr)';
    }
}
.offerLetterLeftBox{
    padding: "5px"
}