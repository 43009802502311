p.Mui-required.MuiFormHelperText-contained.MuiFormHelperText-filled.MuiFormHelperText-marginDense.MuiFormHelperText-root {
  font-size: 14px;
  font-weight: bolder;
}
p.MuiFormHelperText-root.MuiFormHelperText-contained.Mui-required.MuiFormHelperText-marginDense {
  font-size: 14px;
  font-weight: bolder;
}
p.MuiFormHelperText-root.MuiFormHelperText-contained.MuiFormHelperText-marginDense {
  font-size: 14px;
  font-weight: bolder;
}
p.MuiFormHelperText-root.MuiFormHelperText-filled {
  font-size: 14px;
  font-weight: bolder;
}
h2#responsive-dialog-title {
  font-size: 30px;
  font-weight: bolder;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 1000px !important;
}
p.MuiFormHelperText-root {
  font-size: 14px;
  font-weight: bolder;
}
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: gray !important;
}
.MuiFormHelperText-root.Mui-error{
  color: gray !important;
}

.farwordOfferLetter{
  justify-content: center !important;
  height: 250px !important;
  overflow-y: scroll !important;
}

.myDisplay{
  display: none;
}


.offer-preview{
  height: calc(100vh - 200px);
  overflow-y: scroll;
}

.big-tag{
  font-size: 14px;
  padding: 2px 14px;
  font-weight: 400;
  max-width: 100%;
}